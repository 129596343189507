
              @import "@/assets/css/au.scss";
            

@import '@/assets/css/pages/ibAccounts.scss';

.traderTable {
  /deep/ th {
    text-transform: uppercase;
  }
}
