// @import '@/assets/css/components/select.scss';
.query_container {
  font-size: 15px;
  color: $light-white;

  .select {
    width: 34%;

    p {
      margin-bottom: 11px;
      text-align: center;
      font-size: 16px;
      color: $white;
    }

    .none_t {
      font-size: 14px;
      height: 45px;
      text-align: center;
      line-height: 45px;
      color: $white;
      background: #121212;
      border-left: 1px $light-white solid;
    }

    /deep/ .el-select__caret,
    /deep/.el-icon-arrow-down {
      color: $white;
      font-size: 20px;
    }

    /deep/ .el-select .el-input .el-select__caret:before {
      content: '\e6e1';
    }

    /deep/.el-input__inner {
      border: none;
      background: $black2;
      border-radius: 0;
    }

    /deep/.el-cascader {
      width: 100%;
      height: 45px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      background: #121212;
      border-left: 1px $light-white solid;
    }
  }

  .middle {
    align-self: flex-end;
    margin-left: 10px;
  }
}

/deep/.el-table tr {
  background: $theme-hue;
}

.el-pag-container {
  background-color: $theme-hue;
  padding: 0;
  align-items: center;
}