
              @import "@/assets/css/au.scss";
            

@import '@/assets/css/components/rebateReport/dialog.scss';
@import '@/assets/css/pages/home/index.scss';
@import '@/assets/css/components/defaultDatePickerLayout.scss';

/deep/ .el-input {
  .el-input__inner {
    color: $white !important;
  }
}

/deep/ .el-table__cell {
  height: 3.5em !important;
}

/deep/ .el-pagination__total {
  color: $white !important;
}

/deep/ .el-pagination button:disabled {
  color: $white !important;
}
/deep/ .el-table__body-wrapper {
  background-color: #00323c !important;
}

.calendar_content {
  width: 100%;
  margin-top: 1.25rem;
  display: grid;
  align-items: center;
  justify-content: flex-start;
  grid-template-columns: 65% 12%;
  grid-template-rows: auto;
  grid-template-areas: 'calendar right_box';
  column-gap: 3%;
  margin-bottom: 30px;

  .calendar {
    grid-area: calendar;
  }

  .right_box {
    grid-area: right_box;
  }
}

p {
  font-size: 17px;
  color: $white;
  display: inline-block;
}
